class NavItem {
  public id: number
  public name: string
  public color: string

  constructor(id: number, name: string, color: string) {
    this.id = id;
    this.name = name;
    this.color = color;
  }
}

export const navItems:NavItem[]  = [
  new NavItem(0, "red", "crimson"),
  new NavItem(1, "pink", "hotpink"),
  new NavItem(2, "orange", "darkorange"),
  new NavItem(3, "yellow", "gold"),
  new NavItem(4, "purple", "mediumorchid"),
  new NavItem(5, "green", "limegreen"),
  new NavItem(6, "blue", "darkcyan"),
  new NavItem(7, "brown", "tan"),
  new NavItem(8, "white", "whitesmoke"),
  new NavItem(9, "gray", "gray"),
];