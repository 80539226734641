<template>
    <div class="transform-wrapper" style="position: absolute" v-if='page[0].name !== "last"'>
      <div class="color-page" :style="{position:'absolute'}">
        <div :key="color.name" v-for="color in page">
          <Color @change-color="changeColors(color.name)" :color="color" />
        </div>
        <div class="color-page__number"> / {{ }}</div>
      </div>
    </div>
    <div :style="{transform: `translateZ(${this.fanStore.getState().totalPages * -3}px)`}" class="color-page" v-else>
      last
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import Color from './Color.vue'

import { fanStore } from '../util/fan-store';

export default defineComponent({
  name: "ColorPage",
  props: {
    page: Object,
    ix: Number
  },
  data() {
    return {
      fanStore: fanStore
    }
  },
  methods: {
    changeColors(color: string) {
      this.$emit("change-color", color)
    }
  },
  components: {
    Color
  },
  emits: ["change-color"]
})
</script>


<style lang="scss">
  .color-page {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 340px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #fff;

    &__number {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }
  }

  .transform-wrapper {
    width: 100%;
    height: 100%;
    transform-origin: 40px calc(100% - 40px);
  }
</style>
