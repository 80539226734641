
import { defineComponent } from 'vue';
import { fanStore } from '../util/fan-store';

export default defineComponent({
  name: "Color",
  props: {
    color: Object
  },
  data() {
    return {
      fanStore: fanStore
    }
  },
  methods: {
    emitChange(hexString: string) {
      this.fanStore.setSelectedColorName(hexString);
      this.$emit('change-color', hexString);
    },
    copy(event:MouseEvent) {
      const target = event.target as HTMLButtonElement;
      const value = target.dataset.value ?? "";
      navigator.clipboard.writeText(value);
    }
  }
})
