
import { defineComponent } from 'vue';
import { fanStore } from '../util/fan-store';
import { navItems } from '../util/nav-items';

export default defineComponent({
  name: "FloatingNav",
  data() {
    return {
      fanStore: fanStore,
      navItems: navItems,
      colorIndexes: [] as string[],
      interval: undefined as number | undefined
    }
  },
  created() {
    console.log("done")
    console.log(this.navItems)
  },
  methods: {
    changePage(idToNavigateTo:number, colorToNavigateTo:string) {
      // indexes needed to check whether to navigate forward or backward
      let currentIndex: number = navItems.filter(em => em.name === this.fanStore.getState().activeColor)[0].id;
      let nextIndex: number = idToNavigateTo;

      // if another animation is going on, clear the interval before starting a new one
      if( this.interval ) {
        clearInterval( this.interval )
      }

      // start the animation
      let pages = this.fanStore.getState().pages;
      this.interval = setInterval(() => {
        // if navigating forward
        if ( nextIndex > currentIndex ) {
          if( this.fanStore.getState().activeColor !== colorToNavigateTo ) {
            this.fanStore.setActivePage(this.fanStore.getState().activePage + 1);

            if ( pages[this.fanStore.getState().activePage][0]["families"][0] === colorToNavigateTo ) {
              this.fanStore.setActiveColor(colorToNavigateTo, navItems.filter(em => em.name === colorToNavigateTo)[0].id);
              clearInterval(this.interval);
            }
          }
        } else if ( nextIndex < currentIndex ) {
          if( this.fanStore.getState().activeColor !== colorToNavigateTo ) {
            this.fanStore.setActivePage(this.fanStore.getState().activePage - 1);

            if ( pages[this.fanStore.getState().activePage][0]["families"][0] === colorToNavigateTo ) {
              this.fanStore.setActiveColor(colorToNavigateTo, navItems.filter(em => em.name === colorToNavigateTo)[0].id);
              clearInterval(this.interval);
            }
          }
        }
      }, 150)
    }
  }
})
