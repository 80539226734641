import {Store} from "./store";

interface Fan extends Object {
  activePage: number,
  totalPages: number,
  activeColor: string,
  activeColorId: number,
  selectedColorName: string,
  pages: [],
  isTouch: boolean,
  hasGyro: boolean,
  showControlPopup: boolean
}

class FanStore extends Store<Fan> {
    protected data(): Fan {
        return {
          activePage: 0,
          totalPages: 0,
          activeColor: "red",
          activeColorId: 0,
          selectedColorName: "",
          pages: [],
          isTouch: false,
          hasGyro: false,
          showControlPopup: false
        };
    }

    setActivePage(page: number) {
      this.state.activePage = page;
    }

    setTotalPages(pages: number) {
      this.state.totalPages = pages;
    }

    setActiveColor(color: string, id: number) {
      this.state.activeColor = color;
      this.state.activeColorId = id;
    }

    setPages(pages:[]) {
      this.state.pages = pages;
    }

    setSelectedColorName(name: string) {
      this.state.selectedColorName = name;
    }

    setIsTouch(isTouch: boolean) {
      this.state.isTouch = isTouch;
    }

    setShowControlPopup(showPopup: boolean) {
      this.state.showControlPopup = showPopup;
    }

    setHasGyro(hasGyro: boolean) {
      this.state.hasGyro = hasGyro;
    }
}

export const fanStore: FanStore = new FanStore()