<template>
  <div class="transform-wrapper">
    <div class="fan-case">
      <h1 class="page-title">CSS<br />COLOR<br />FAN<br /></h1>
      <h3>A collection of cross-browser supported CSS color names.</h3>
      <h4>by <b>Slawa Gurevich</b></h4>
      <div class="buttons">
        <button class="info" @click="$emit('show-info-window')">
          <i class="far fa-question-circle"></i>
        </button>
        <button class="start" @click="$emit('start')">
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "FanCase",
  data() {
    return {
      started: false
    }
  }
});
</script>

<style lang="scss" scoped>
@import '../scss/main';

.buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: right;
  padding: $spacer__l;
}

button {
    background: none;
    border: none;
    font-size: 2em;
    color: white;
    cursor: pointer;
}

.transform-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 380px;
  height: 100%;
  transform-style: preserve-3d;
  border-radius: 10px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 1003;
}

.fan-case {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(min(70vh, 1000px));
  width: 380px;
  padding: $spacer__xl;
  border-radius: 15px;
  color: white;
  background: linear-gradient(to bottom right, orangered, darken(orangered, 4%));
  text-align: left;
}

.page-title {
  position: relative;
  margin: 0;
  padding: $spacer__xl 0;
  text-align: left;
  font-size: 5rem;
  line-height: 1em;
  font-family: Abel, sans-serif;
  font-weight: 700;
  color: white;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    background-color: white;
    border-radius: 1.5px;
    height: 3px;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

@media screen and (max-width: $media-query__size-s) {
.fan-case {
  width: 360px;
  height: calc(min(80vh, 800px));
}
}
</style>