<template>
  <div class="color" @mouseover="emitChange(color.name)">
    <div class="color-preview" :style="{ background: color.name }">
      <div class="color-controls">
        <div>copy</div>
        <button :data-value="color.name" @click="copy">name</button>
        <button :data-value="color.hex" @click="copy">hex</button>
        <button :data-value="color.rgb" @click="copy">rgb</button>
      </div>
    </div>
    <div class="color-meta">
      <div class="color-meta-inner">
        <span class="color-name">{{ color.name.toLowerCase() }},</span><span class="color-hex">{{ color.hex }},</span> <span class="color-rgb">{{ color.rgb }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { fanStore } from '../util/fan-store';

export default defineComponent({
  name: "Color",
  props: {
    color: Object
  },
  data() {
    return {
      fanStore: fanStore
    }
  },
  methods: {
    emitChange(hexString: string) {
      this.fanStore.setSelectedColorName(hexString);
      this.$emit('change-color', hexString);
    },
    copy(event:MouseEvent) {
      const target = event.target as HTMLButtonElement;
      const value = target.dataset.value ?? "";
      navigator.clipboard.writeText(value);
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../scss/global-variables';

.color {
  margin-bottom: $spacer__l;

  &-controls {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: all 300ms ease-in-out;

    button {
      margin: 0 $spacer__s;
      padding: $spacer__m;
      border: none;
      border-radius: 5px;
      background-color: #252525;
      color: white;
      cursor: pointer;
    }
  }

  &-preview {
    width: 100%;
    height: 7vh;

    &:hover,
    &:active {
      .color-controls {
        visibility: visible;
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
  }

  &-meta {
    display: flex;
    padding: $spacer__m;
    color: white;
    font-size: .9rem;
    font-weight: 500;
    background-color: #252525;
    text-align: left;

    span {
      margin-right: $spacer__s;
    }
  }

  &-name {
    font-weight: 700;
  }
}

@media screen and (max-width: $media-query__size-s) {
.color {
  margin-bottom: $spacer__m;

  &-meta {
    padding: $spacer__s $spacer__m;
  }
}
}
</style>