<template>
  <nav class="floating-nav">
    <template :key="navItem.id" v-for="navItem in this.navItems">
      <a @click="this.changePage(navItem.id, navItem.name)" :class="{ active: navItem.name === this.fanStore.getState().activeColor }" class="nav-item" :style="{color: navItem.color}">
        <span class="nav-item__name">{{ navItem.name }}</span>
        <span class="nav-item__dot" :style="{backgroundColor: navItem.color }"></span>
      </a>
    </template>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { fanStore } from '../util/fan-store';
import { navItems } from '../util/nav-items';

export default defineComponent({
  name: "FloatingNav",
  data() {
    return {
      fanStore: fanStore,
      navItems: navItems,
      colorIndexes: [] as string[],
      interval: undefined as number | undefined
    }
  },
  created() {
    console.log("done")
    console.log(this.navItems)
  },
  methods: {
    changePage(idToNavigateTo:number, colorToNavigateTo:string) {
      // indexes needed to check whether to navigate forward or backward
      let currentIndex: number = navItems.filter(em => em.name === this.fanStore.getState().activeColor)[0].id;
      let nextIndex: number = idToNavigateTo;

      // if another animation is going on, clear the interval before starting a new one
      if( this.interval ) {
        clearInterval( this.interval )
      }

      // start the animation
      let pages = this.fanStore.getState().pages;
      this.interval = setInterval(() => {
        // if navigating forward
        if ( nextIndex > currentIndex ) {
          if( this.fanStore.getState().activeColor !== colorToNavigateTo ) {
            this.fanStore.setActivePage(this.fanStore.getState().activePage + 1);

            if ( pages[this.fanStore.getState().activePage][0]["families"][0] === colorToNavigateTo ) {
              this.fanStore.setActiveColor(colorToNavigateTo, navItems.filter(em => em.name === colorToNavigateTo)[0].id);
              clearInterval(this.interval);
            }
          }
        } else if ( nextIndex < currentIndex ) {
          if( this.fanStore.getState().activeColor !== colorToNavigateTo ) {
            this.fanStore.setActivePage(this.fanStore.getState().activePage - 1);

            if ( pages[this.fanStore.getState().activePage][0]["families"][0] === colorToNavigateTo ) {
              this.fanStore.setActiveColor(colorToNavigateTo, navItems.filter(em => em.name === colorToNavigateTo)[0].id);
              clearInterval(this.interval);
            }
          }
        }
      }, 150)
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../scss/global-variables";

.floating-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50px;
  padding: 0 $spacer__m;
  border-radius: $border-radius--soft;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  background-color: white;
  transform: translateY(0) translateX(-50%);
  text-transform: uppercase;
  z-index: 10001;
}

.nav-item {
  position: relative;
  padding: $spacer__l;
  font-weight: 700;
  cursor: pointer;

    &__dot {
      display: none;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;

      &::after {
        content: '';
        width: 0px;
        height: 0px;
        border-radius: 50%;
        background-color: white;
        transition: all 300ms ease-in-out;
      }
    }

  &.active {
    .nav-item__dot {
      &::after {
        width: 8px;
        height: 8px;
      }
    }
  }

  &::after {
    content: '•';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease-in-out;
  }

  &.active {
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

@media screen and (max-width: $media-query__size-m) {
.nav-item {
  padding: $spacer__ml $spacer__m;

  &:after {
    display: none;
  }

  &__name {
    display: none;
  }

  &__dot {
    display: flex;
  }
}
}

@media screen and (max-width: $media-query__size-s) {
.floating-nav {
  top: $spacer__l;
}

.nav-item {
  padding: $spacer__m 5px;

  &:after {
    display: none;
  }

  &__name {
    display: none;
  }

  &__dot {
    display: flex;
  }
}
}
</style>