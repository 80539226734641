<template>
  <div class="color-fan">
    <div class="color-fan-animation-wrapper">
      <button data-direction="back" :disabled="this.fanStore.getState().activePage === 0" class="pageturner pageturner-back"><i class="fas fa-arrow-left"></i></button>
      <button data-direction="next" :disabled="this.fanStore.getState().activePage === this.fanStore.getState().totalPages - 1" class="pageturner pageturner-next"><i class="fas fa-arrow-right"></i></button>
      <div class="color-fan__pin" :style="{transform: `translateZ(${-3 * this.fanStore.getState().activePage}px)`}"></div>
      <template class="color-page" :key="ix" v-for="(page, ix) in pages">
        <div class="animation-helper" :style="{transform:`translateZ(${ix * -3}px)`}">
          <transition name="turn">
            <ColorPage :ix="ix" :style="{zIndex: pages.length - ix}" @change-color="emitChange" v-if="this.fanStore.getState().activePage <= ix" :page="page" />
          </transition>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { fanStore } from '../util/fan-store';
import { navItems } from '../util/nav-items';

import ColorPage from './ColorPage.vue';

export default defineComponent({
  name: 'Fan',
  props: {
    pages: {
      type: Object,
      default: null
    }
  },
  components: {
    ColorPage
  },
  data() {
    return {
      activePage: 0 as number,
      activeColor: '' as string,
      colorIndexes: [] as string[],
      fanStore: fanStore,
      touchStartX: 0 as number,
      touchEndX: 0 as number
    }
  },
  emit: ['change-color'],
  methods: {
    map(value:number, x1:number, y1:number, x2:number, y2:number) {
      return (value - x1) * (y2 - x2) / (y1 - x1) + x2
    },

    emitChange(color: string) {
      this.$emit("change-color", color)
    },

    navigate(event: KeyboardEvent | WheelEvent | TouchEvent | PointerEvent | Event) {
      let target:HTMLButtonElement = document.createElement("button"),
          direction = "";

      if ( event instanceof PointerEvent) {
        target = event.target as HTMLButtonElement;
      }

      this.fanStore.setShowControlPopup(false);

      if( event instanceof KeyboardEvent && event.code === "ArrowLeft"
          || event instanceof WheelEvent && Math.abs(event.deltaY) > 20 && event.deltaY < 0
          || event instanceof MouseEvent && target.dataset.direction === "back"
          || event instanceof TouchEvent && (Math.abs(this.touchStartX - this.touchEndX) > 40) && this.touchStartX > this.touchEndX ) {
            direction = "back"
      } else if( event instanceof KeyboardEvent && event.code === "ArrowRight"
          || event instanceof WheelEvent && Math.abs(event.deltaY) > 20 && event.deltaY > 0
          || event instanceof PointerEvent && target.dataset.direction === "next"
          || event instanceof TouchEvent && (Math.abs(this.touchStartX - this.touchEndX) > 40) && this.touchStartX < this.touchEndX) {
            direction = "next";
      }

      console.log(direction)

      if ( direction === "back") {
        this.fanStore.setActivePage(this.fanStore.getState().activePage > 0 ? this.fanStore.getState().activePage - 1 : this.fanStore.getState().activePage);
      } else if (direction === "next") {
        this.fanStore.setActivePage(this.fanStore.getState().activePage < this.fanStore.getState().totalPages - 1  ? this.fanStore.getState().activePage + 1 : this.fanStore.getState().activePage);
      }

      if (this.fanStore.getState().activePage === this.fanStore.getState().totalPages) {
        this.fanStore.setActiveColor(
          navItems[-1].name,
          this.fanStore.getState().totalPages
        );
      } else {
        this.fanStore.setActiveColor(
          this.pages[this.fanStore.getState().activePage][0].families[0],
          this.colorIndexes.indexOf( this.pages[this.fanStore.getState().activePage][0].families[0] )
        );
      }
    },

    mouseMoved(event: MouseEvent) {
      let screenWidth:number = window.innerWidth;
      let mouseX:number = event.screenX;

      let fan = document.querySelector(".color-fan-animation-wrapper") as HTMLDivElement;

      let amount = 10;
      if( fan ) {
        fan.style.transform = `perspective(2000px) rotateY(${this.map(mouseX, 0, screenWidth, -amount, amount)}deg)`;
      }
    },

    checkGyro(e: DeviceOrientationEvent) {
      let amount = 50
      if (e.gamma) {
        let rotation = this.map(e.gamma, -90, 89, amount, -amount).toFixed(2);
        console.log(rotation);
        let fan = document.querySelector(".color-fan-animation-wrapper") as HTMLDivElement;
        console.log(fan);
        if( fan ) {
         fan.style.transform = `perspective(2000px) rotateY(${rotation}deg)`;
        }
      }
    },
  },
  mounted() {
    let touchDevice = this.fanStore.getState().isTouch;

    let pageturnerBack = document.getElementsByClassName("pageturner-back")[0];
    let pageturnerNext = document.querySelector(".pageturner-next");

    if( pageturnerBack !== null ) {
      console.log("page")
      pageturnerBack.addEventListener("click", (e) => this.navigate(e));
    }
    if( pageturnerNext !== null ) {
      pageturnerNext.addEventListener("click", (e) => this.navigate(e));
    }

    if( !touchDevice ) {
      window.addEventListener("mousemove", this.mouseMoved);
      window.addEventListener("wheel", this.navigate);
      window.addEventListener("keyup", this.navigate);
    } else {
      window.addEventListener("touchstart", (e) => {
        this.touchStartX = e.touches[0].clientX;
        this.touchEndX = e.touches[0].clientX;
      })

      window.addEventListener("touchmove", (e) => {
        this.touchEndX = e.touches[0].clientX;
      })

      window.addEventListener("touchend", this.navigate)

      if(this.fanStore.getState().hasGyro) {
        window.addEventListener("deviceorientation", this.checkGyro);
      }
    }
  },
  unmounted() {
    window.removeEventListener("mousemove", this.mouseMoved);
    window.removeEventListener("deviceorientation", this.checkGyro);
    window.removeEventListener("touchend", this.navigate)
  }
})
</script>

<style lang="scss" scoped>
@import '../scss/global-variables';

.animation-helper {
  &:last-child {
    .color-page {
      box-shadow: $box-shadow__default;
    }
  }
}

.color-fan {
  width: 340px;
  height: calc(min(70vh, 1000px));

  &-animation-wrapper {
    display: flex;
    position: relative;
    height: 100%;
    transform-style: preserve-3d;
  }

  &__pin {
    position: absolute;
    content: '';
    bottom: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #fff;
    background: white;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .3);
    z-index: 1000;
  }
}

.color-page--last {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
}

.turn-enter-from,
.turn-leave-to {
  transition: all 1s ease-out;
  transform: rotateZ(50deg) scale(1.15);
  box-shadow: 0 5px 50px 0px rgba(0, 0, 0, .5);
  opacity: 0;
  pointer-events: none;
}

.turn-enter-to,
.turn-leave-from {
  transition: all 1s ease-out;
  transform: rotateZ(0deg) scale(1);
  opacity: 1;
  box-shadow: 0 5px 50px 0px rgba(0, 0, 0, 0);
  pointer-events: all;
}

.slide-down-enter-from {
  transition: all 300ms ease-in-out;
  transform: translateY(-50%) translateX(-50%);
  opacity: 0;
}

.slide-down-enter-to {
  transition: all 300ms ease-in-out;
  transform: translateY(0) translateX(-50%);
  opacity: 1;
}

.pageturner {
  position: absolute;
  bottom: 50px;
  background: none;
  border: none;
  color: white;
  font-size: 2.5rem;
  cursor: pointer;
  text-shadow: 4px 6px 10px rgba(0, 0, 0, .2);
  transition: all 300ms ease-in-out;

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  i {
    pointer-events: none;
  }

  &-back {
    left: -80px;

    &:hover {
      transform: translateX(-5px);
    }
  }

  &-next {
    right: -80px;

     &:hover {
      transform: translateX(5px);
    }
  }
}

@media screen and (max-width: $media-query__size-s) {
.color-fan {
  height: calc(min(80vh, 800px));
}

.pageturner {
  position: absolute;
  bottom: 20px;
  z-index: 2000;

  &-back {
    right: 80px;
    left: unset;
  }

  &-next {
    right: 20px;
  }
}
}
</style>