
import { defineComponent } from 'vue';
import Color from './Color.vue'

import { fanStore } from '../util/fan-store';

export default defineComponent({
  name: "ColorPage",
  props: {
    page: Object,
    ix: Number
  },
  data() {
    return {
      fanStore: fanStore
    }
  },
  methods: {
    changeColors(color: string) {
      this.$emit("change-color", color)
    }
  },
  components: {
    Color
  },
  emits: ["change-color"]
})
