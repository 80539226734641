<template>
  <div class="control-info-popup">
    <span class="headline"><b>To navigate</b></span><br />
    <div class="explanation-touch" v-if="this.fanStore.getState().isTouch">
      <i class="fas fa-mouse-pointer"></i> - buttons <i class="fas fa-arrow-left"></i> and <i class="fas fa-arrow-right"></i><br />
      <i class="fas fa-mobile-alt"></i> - Swipe <i class="fas fa-angle-left"></i> left or <i class="fas fa-angle-right"></i> right
    </div>
    <div class="explanation-non-touch" v-else>
      <i class="fas fa-mouse-pointer"></i> - buttons <i class="fas fa-arrow-left"></i> and <i class="fas fa-arrow-right"></i><br />
      <i class="fas fa-keyboard"></i> - arrow keys <i class="fas fa-caret-left"></i> and <i class="fas fa-caret-right"></i><br />
      <i class="fas fa-mouse"></i> - mouse wheel<br />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { fanStore } from '../util/fan-store';

export default defineComponent({
  name: "ControlInfoPopup",
  setup() {
    console.log("control info");
  },
  data() {
    return {
      fanStore: fanStore
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../scss/global-variables";

.control-info-popup {
  position: fixed;
  bottom: $spacer__xl;
  padding: $spacer__l;
  border-radius: $border-radius__default;
  box-shadow: $box-shadow__default;
  background-color: white;
}

.headline {
  margin-bottom: $spacer__m;
}
</style>