<template>
  <div class="info-window__background">
    <div class="info-window">
      <header>
        <h2>What is this?</h2>
        <button @click='$emit("close-popup")' class="button__close-popup as-icon"><i class="fas fa-times"></i></button>
      </header>
      <article>
        <img class="profile-image" src="../../public/portrait.png" alt="Slawa">
        <h1>Guten Tag!</h1>
        <p>How nice of you to pay me a visit. "What the heck is this website" you ask yourself? It is a color fan for CSS colors, that can be used by their names instead of the hex value.</p>
        <p>"Why does this exist" you scream from the top of your lungs? Well, because 1) it gives you an overview of the usable css colors, 2) you can find inspiration in what colors to use and 3) it's fun.</p>
        <p><b>If you like to know more about my work, visit me on my other media.</b></p>
        <p class="links">
          <a target="_blank" class="github" href="http://github.com"><i class="fab fa-github-square"></i></a>
          <a target="_blank" class="linkedin" href="https://likedin.com"><i class="fab fa-linkedin"></i></a>
          <a target="_blank" class="website" href="https://slawagurevich.com"><i class="fas fa-globe-europe"></i></a>
        </p>
      </article>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: "InfoWindow",
  mounted() {
    console.log("asd");
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
})
</script>

<style scoped lang="scss">
@import '../scss/global-variables';

.info-window {
  min-width: 340px;
  max-width: 400px;
  min-height: 300px;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .8);

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacer__m $spacer__ml;
    border-bottom: 1px solid #ddd;

    h2 {
      margin: 0;
    }
  }

  article {
    padding: $spacer__l;

    .profile-image {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      margin-bottom: $spacer__l;
    }

    h1 {
      margin-top: 0;
    }

    .links {
      font-size: 2rem;
      margin-top: $spacer__m;

      a {
        padding: 0 $spacer__s;

        &.linkedin {
          color: #0E76A8;
        }

        &.github {
          color: #6E5494;
        }

        &.website {
          font-size: 1.9rem;
          color: steelblue;
        }
      }
    }
  }

  &__background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $spacer__m;
    background-color: rgba(0, 0, 0, .4);
    z-index: 2000;
  }
}
</style>
