
import { defineComponent } from 'vue';

import { fanStore } from '../util/fan-store';
import { navItems } from '../util/nav-items';

import ColorPage from './ColorPage.vue';

export default defineComponent({
  name: 'Fan',
  props: {
    pages: {
      type: Object,
      default: null
    }
  },
  components: {
    ColorPage
  },
  data() {
    return {
      activePage: 0 as number,
      activeColor: '' as string,
      colorIndexes: [] as string[],
      fanStore: fanStore,
      touchStartX: 0 as number,
      touchEndX: 0 as number
    }
  },
  emit: ['change-color'],
  methods: {
    map(value:number, x1:number, y1:number, x2:number, y2:number) {
      return (value - x1) * (y2 - x2) / (y1 - x1) + x2
    },

    emitChange(color: string) {
      this.$emit("change-color", color)
    },

    navigate(event: KeyboardEvent | WheelEvent | TouchEvent | PointerEvent | Event) {
      let target:HTMLButtonElement = document.createElement("button"),
          direction = "";

      if ( event instanceof PointerEvent) {
        target = event.target as HTMLButtonElement;
      }

      this.fanStore.setShowControlPopup(false);

      if( event instanceof KeyboardEvent && event.code === "ArrowLeft"
          || event instanceof WheelEvent && Math.abs(event.deltaY) > 20 && event.deltaY < 0
          || event instanceof MouseEvent && target.dataset.direction === "back"
          || event instanceof TouchEvent && (Math.abs(this.touchStartX - this.touchEndX) > 40) && this.touchStartX > this.touchEndX ) {
            direction = "back"
      } else if( event instanceof KeyboardEvent && event.code === "ArrowRight"
          || event instanceof WheelEvent && Math.abs(event.deltaY) > 20 && event.deltaY > 0
          || event instanceof PointerEvent && target.dataset.direction === "next"
          || event instanceof TouchEvent && (Math.abs(this.touchStartX - this.touchEndX) > 40) && this.touchStartX < this.touchEndX) {
            direction = "next";
      }

      console.log(direction)

      if ( direction === "back") {
        this.fanStore.setActivePage(this.fanStore.getState().activePage > 0 ? this.fanStore.getState().activePage - 1 : this.fanStore.getState().activePage);
      } else if (direction === "next") {
        this.fanStore.setActivePage(this.fanStore.getState().activePage < this.fanStore.getState().totalPages - 1  ? this.fanStore.getState().activePage + 1 : this.fanStore.getState().activePage);
      }

      if (this.fanStore.getState().activePage === this.fanStore.getState().totalPages) {
        this.fanStore.setActiveColor(
          navItems[-1].name,
          this.fanStore.getState().totalPages
        );
      } else {
        this.fanStore.setActiveColor(
          this.pages[this.fanStore.getState().activePage][0].families[0],
          this.colorIndexes.indexOf( this.pages[this.fanStore.getState().activePage][0].families[0] )
        );
      }
    },

    mouseMoved(event: MouseEvent) {
      let screenWidth:number = window.innerWidth;
      let mouseX:number = event.screenX;

      let fan = document.querySelector(".color-fan-animation-wrapper") as HTMLDivElement;

      let amount = 10;
      if( fan ) {
        fan.style.transform = `perspective(2000px) rotateY(${this.map(mouseX, 0, screenWidth, -amount, amount)}deg)`;
      }
    },

    checkGyro(e: DeviceOrientationEvent) {
      let amount = 50
      if (e.gamma) {
        let rotation = this.map(e.gamma, -90, 89, amount, -amount).toFixed(2);
        console.log(rotation);
        let fan = document.querySelector(".color-fan-animation-wrapper") as HTMLDivElement;
        console.log(fan);
        if( fan ) {
         fan.style.transform = `perspective(2000px) rotateY(${rotation}deg)`;
        }
      }
    },
  },
  mounted() {
    let touchDevice = this.fanStore.getState().isTouch;

    let pageturnerBack = document.getElementsByClassName("pageturner-back")[0];
    let pageturnerNext = document.querySelector(".pageturner-next");

    if( pageturnerBack !== null ) {
      console.log("page")
      pageturnerBack.addEventListener("click", (e) => this.navigate(e));
    }
    if( pageturnerNext !== null ) {
      pageturnerNext.addEventListener("click", (e) => this.navigate(e));
    }

    if( !touchDevice ) {
      window.addEventListener("mousemove", this.mouseMoved);
      window.addEventListener("wheel", this.navigate);
      window.addEventListener("keyup", this.navigate);
    } else {
      window.addEventListener("touchstart", (e) => {
        this.touchStartX = e.touches[0].clientX;
        this.touchEndX = e.touches[0].clientX;
      })

      window.addEventListener("touchmove", (e) => {
        this.touchEndX = e.touches[0].clientX;
      })

      window.addEventListener("touchend", this.navigate)

      if(this.fanStore.getState().hasGyro) {
        window.addEventListener("deviceorientation", this.checkGyro);
      }
    }
  },
  unmounted() {
    window.removeEventListener("mousemove", this.mouseMoved);
    window.removeEventListener("deviceorientation", this.checkGyro);
    window.removeEventListener("touchend", this.navigate)
  }
})
