<template>
  <div :style="{backgroundColor: this.currentColor}" class="wrapper">
    <div :style="cssVars" class="current-color-name">{{ this.fanStore.getState().selectedColorName }}</div>
    <transition name="open" appear>
      <FanCase v-if="!this.started" @show-info-window="this.toggleInfoWindow" @start="this.start" />
    </transition>
    <transition name="exit-case">
      <Fan v-if="this.started" @change-color="changeColor" :pages="this.fanStore.getState().pages" />
    </transition>
    <transition name="slide-down" style="transition-delay: .5s;">
      <FloatingNav v-if="this.started" @change-page="changePage" />
    </transition>
    <transition name="info-window">
      <InfoWindow @close-popup="this.showInfoWindow = false" v-if="this.showInfoWindow" />
    </transition>
    <transition name="info-popup">
      <ControlInfoPopup v-if="this.fanStore.getState().showControlPopup" />
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { fanStore } from './util/fan-store';
import colors from './util/colors';

import Fan from './components/Fan';
import FanCase from './components/FanCase';
import FloatingNav from './components/FloatingNav.vue';
import InfoWindow from './components/InfoWindow.vue';
import ControlInfoPopup from './components/ControlInfoPopup.vue';

export default defineComponent({
  name: 'App',
  components: {
    Fan,
    FanCase,
    FloatingNav,
    InfoWindow,
    ControlInfoPopup
  },
  data() {
    return {
      chunks: [],
      currentColor: "#fff",
      started: false,
      fanStore: fanStore,
      showInfoWindow: false,
      colors: colors
    }
  },
  computed: {
    cssVars() {
      return {
        color: `white`
      }
    }
  },
  methods: {
    changeColor(color) {
      console.log(color);
      this.currentColor = color;
    },
    start() {
      this.started = true;
      this.fanStore.setShowControlPopup(true);
    },
    toggleInfoWindow() {
      this.showInfoWindow = !this.showInfoWindow;
    },
    checkGyro(e) {
      this.fanStore.setHasGyro(true);
      window.removeEventListener('deviceorientation', this.checkGyro);
    }
  },

  async created() {
    const chunks = []
    const arrays = {}

    this.fanStore.setIsTouch((('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)));

    console.log("colors", this.colors);

    for( let color of this.colors ) {
      if( !Object.keys(arrays).includes(color.families[0]) ) {
        arrays[color.families[0]] = []
      }

      arrays[color.families[0]].push(color);
    }

    console.log(arrays)

    while (this.colors.length) {
      chunks.push(
        this.colors.splice(0, 5)
      )
    }

    chunks.push([{
      "name": "last",
      "hex": "",
      "rgb": "",
      "families": ["gray"]
    }])

    this.fanStore.setTotalPages(chunks.length);
    this.fanStore.setPages(chunks);

    window.addEventListener("deviceorientation", this.checkGyro);
  }
})
</script>

<style lang="scss">
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: background 300ms ease-in-out;
}

.current-color-name {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 18vw;
  font-family: Abel, sans-serif;
  color: var(--selected-color);
}

@keyframes enter-case {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.open-enter-active {
  animation: enter-case 2s;
  animation-timing-function: linear;
}

@keyframes remove-case {
  0% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }

  50% {
    transform: scale(1) translateX(-110%);
    z-index: 0;
    opacity: 1;
  }

  100% {
    transform: scale(0.95) translateX(0);
    z-index: 0;
    opacity: 0;
  }
}

.open-leave-active {
  animation: remove-case 1.5s;
}

.exit-case-enter-from {
  transform: perspective(2000px) scale(.96);
  transition: all 1000ms ease-in-out;
  transition-delay: .5s;
}

.exit-case-enter-to {
  transform: perspective(2000px) scale(1);
  transition: all 1000ms ease-in-out;
  transition-delay: .5s;
}

@keyframes info-window {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.info-window-enter-active,
.info-window-leave-active {
  animation: info-window 300ms;
  animation-timing-function: ease-in-out;
}

.info-window-leave-active {
  animation-direction: reverse;
}

.floating-nav.slide-down-enter-from {
  opacity: 0;
  transform: translateY(-100%) translateX(-50%);
  visibility: hidden;
  transition: all 1s ease-in-out;
}

.floating-nav.slide-down-enter-to {
  opacity: 1;
  transform: translateY(0) translateX(-50%);
  visibility: visible;
  transition: all 1s ease-in-out;
}

.info-popup-enter-from,
.info-popup-leave-to {
  opacity: 0;
  transform: translateY(3vh);
  transition: all 700ms ease-in-out;
  transition-delay: .5s;
}

.info-popup-enter-to,
.info-popup-leave-from {
  opacity: 1;
  transform: translateY(0);
  transition: all 400ms ease-in-out;
  transition-delay: 0s;
}
</style>

